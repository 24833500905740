import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography } from "@mui/material";
import { Candidate, CandidateList, Election, Vote } from "../../models/apiSchema";
import { useEffect, useState } from "react";
import CandidateItem from "./CandidateItem";
import { useMutation } from "react-query";
import { castElectionVote } from "../../services/electionService";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import { useConfirm } from "material-ui-confirm";
import { castLinkElectionVote } from "../../services/electionLinkService";

interface VoteModalProps {
  election: Election;
  vote: Vote | null;
  producerNumber?: string;
  signature?: string | null;
  onClose: (result: boolean) => void;
}

const VoteModal: React.FC<VoteModalProps> = ({ election, vote, onClose, producerNumber, signature }) => {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();

  const [candidateList, setCandidateList] = useState<CandidateList | null>(null);
  const [candidate, setCandidate] = useState<Candidate | null>(null);

  const [isVoting, setIsVoting] = useState<boolean>(false);
  const [voteError, setVoteError] = useState<AxiosError | null>(null);

  useEffect(() => {
    if (!vote) {
      setCandidateList(null);
      setCandidate(null);
      return;
    }
    const _candidateList = election.candidateLists.find(cl => cl.key === vote.candidateListKey);
    const _candidate = _candidateList?.candidates.find(c => c.key === vote.candidateKey);
    setCandidateList(_candidateList || null);
    setCandidate(_candidate || null);
  }, [vote, election.candidateLists]);

  const castVote = useMutation((vote: Vote) =>
    producerNumber && signature
      ? castLinkElectionVote(election.id, vote, producerNumber, signature)
      : castElectionVote(election.id, vote),
    {
      onSuccess: () => {
        setIsVoting(false);
        enqueueSnackbar('Aänesi on vastaanotettu' , { variant: 'success' });
        onClose(true);
      },
      onError: (e, v, c) => {
        setIsVoting(false);
        setVoteError(e as AxiosError);
      }
    }
  );

  const handleVote = () => {
    confirm({description: 'Ymmärrän että antamani ääni on lopullinen eikä sitä voi muuttaa.'}).then(() => {
      setVoteError(null);
      if (vote && !isVoting) {
        setIsVoting(true);
        castVote.mutate(vote);
      }
    }).catch(() => {});
  }

  const handleClose = (result: boolean) => {
    setVoteError(null);
    setIsVoting(false);
    onClose(result);
  }

  if (!candidateList || !candidate) return null;

  return (
    <Dialog maxWidth="sm" fullWidth onClose={() => handleClose(false)} open={!!vote}>
      <DialogTitle>{election.title}</DialogTitle>
      <DialogContent>
        <Typography variant="h5" color="primary" marginBottom={2}>
          Olet antamassa äänesi ehdokkaalle:
        </Typography>
        <Paper variant="outlined">
          <CandidateItem candidate={candidate} button={false} />
        </Paper>
        <Alert severity="info" sx={{ marginTop: 2 }}>
          Huomioi, että äänen antaminen on lopullista eikä sitä voi peruuttaa tai myöhemmin muuttaa.
        </Alert>
        {voteError && <Alert severity="error" sx={{ marginTop: 2 }}>
          Äänestys epäonnistui: {voteError.response?.data.detail || 'Tuntematon virhe'}
        </Alert>}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleClose(false)} color="secondary" disabled={isVoting}>
          Peruuta
        </Button>
        <Button onClick={() => handleVote()} color="primary" disabled={isVoting}>
          Äänestä
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VoteModal;