import { Election, ElectionStatus, Vote } from "../models/apiSchema";
import http from "./httpService";

const API_URL = process.env.REACT_APP_API_BASE_URL + '/elections';

export const getElections = async (params?: any) => {
  const resp = await http.get<Election[]>(`${API_URL}`, { params });
  return resp.data;
};

export const getElection = async (electionId: string) => {
  const resp = await http.get<Election>(`${API_URL}/${electionId}`);
  return resp.data;
};

export const getElectionStatus = async (electionId: string) => {
  const resp = await http.get<ElectionStatus>(`${API_URL}/${electionId}/status`);
  return resp.data;
};

export const castElectionVote = async (electionId: string, vote: Vote) => {
  const resp = await http.post<void>(`${API_URL}/${electionId}/vote`, vote);
  return resp.data;
};
