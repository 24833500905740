import React from "react";
import { Box, styled } from "@mui/system";
import { Avatar, List, ListItemAvatar, Alert, ListItemText, ListItem, Typography, Button, Chip } from "@mui/material";
import { useQuery } from "react-query";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import QueryResult from "../../shared/components/QueryResult/QueryResult";
import { AxiosError } from "axios";
import { ElectionResult } from "../../shared/models/apiSchema";
import PollIcon from '@mui/icons-material/Poll';
import dayjs from "dayjs";
import { downloadElectionResult, getElectionResults } from "../../shared/services/resultsService";

const ResultsContainer = styled(Box)({
  minHeight: '100%'
});

const ElectionAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main
}));

const Results: React.FC = () => {

  const results = useQuery<ElectionResult[],AxiosError>('results', () => getElectionResults());

  const [downloading, setDownloading] = React.useState(false);

  async function handleDownload(electionId: string, filename: string) {
    setDownloading(true);
    await downloadElectionResult(electionId, filename);
    setDownloading(false);
  }

  return (
    <ResultsContainer>
      <PageHeader level="1">Vaalien tulokset</PageHeader>
      <Box mt={1}>
        <QueryResult query={results} result={(data) => data.length > 0
          ? <List>{data.map((election) =>
              <ListItem key={election.id} dense secondaryAction={
                <Button variant="contained" color="secondary" size="small" disabled={downloading} onClick={() => handleDownload(election.id, election.title + '.xlsx')}>
                  Lataa Excel
                </Button>
              }>
                <ListItemAvatar>
                  <ElectionAvatar>
                    <PollIcon />
                  </ElectionAvatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="h5">
                    {election.title}
                    {election.voteCount !== null &&
                      <Chip size="small" color="success" sx={{"marginLeft": "8px"}} label={<span>
                        <span>Ääniä annettu {election.voteCount} kpl</span>
                        {election.voterCount !== null && election.voterCount > 0 &&
                          <span>, aktiivisuus {Math.round((election.voteCount || 0) / election.voterCount * 1000) / 10} %</span>
                        }
                      </span>} />}
                  </Typography>}
                  secondary={<>
                    Äänestysaika: {dayjs(election.votingStarts).format('D.M.YYYY H:mm')}
                    {" - "}
                    {dayjs(election.votingEnds).format('D.M.YYYY H:mm')}
                  </>}
                />
              </ListItem>
            )}</List>
          : <Alert severity="info">Vaalituloksia ei ole saatavilla</Alert>
        } />
      </Box>
    </ResultsContainer>
  );
};

export default Results;
