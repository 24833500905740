import { Avatar, Box, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography, styled } from "@mui/material";
import { Candidate } from "../../models/apiSchema";

const CandidateAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  height: '48px',
  width: '48px'
}));

interface CandidateItemProps {
  candidate: Candidate;
  button?: boolean;
  onVote?: (candidate: Candidate) => void;
}

const CandidateItem: React.FC<CandidateItemProps> = ({ candidate, button = true, onVote = null }) => {

  const avatar = (
    <ListItemAvatar sx={{ paddingRight: '1em'}}>
      <CandidateAvatar alt={candidate.key}>
        <Typography variant="h4" margin={0}>{candidate.key}</Typography>
      </CandidateAvatar>
    </ListItemAvatar>
  );

  const text = (
    <ListItemText
      primary={
        <Box display="flex" justifyContent={"space-between"}>
          <Typography variant="h4" color="primary" marginTop="0.1em">
            {candidate.name}
          </Typography>
          {candidate.properties.municipality &&
          <Typography variant="h4" color="primary" marginTop="0.1em">
            {candidate.properties.municipality}
          </Typography>
          }          
        </Box>
      }
      secondary={candidate.description &&
        <Typography variant="body1" color="textSecondary">
          <span>{candidate.description}</span>
        </Typography>
      }
    />
  );

  if (button) return (
    <ListItem disablePadding>
      <ListItemButton onClick={() => onVote ? onVote(candidate) : null}>
        {avatar}
        {text}
      </ListItemButton>
    </ListItem>    
  );

  return (
    <ListItem>
      {avatar}
      {text}
    </ListItem>    
  );
}

export default CandidateItem;
