import { CssBaseline, Theme, ThemeProvider } from "@mui/material";
import createTheme from "./shared/themes";
import { useEffect, useState } from "react";
import { Localization } from "@mui/material/locale";
import { getLocalization } from "./config/mui";
import initDayjs from "./config/dayjs";
import { useAppSelector } from "./shared/store/hooks";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import { ConfirmProvider } from "material-ui-confirm";
import AnonMain from "./shared/components/AnonMain/AnonMain";

const AnonApp = () => {
  const locale = useAppSelector(state => state.ui.i18n.locale);
  const [localization, setLocalization] = useState<Localization>(getLocalization(locale));
  const [theme, setTheme] = useState<Theme>(createTheme(localization));

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1
      }
    },
  });

  const confirmOptions = {
    title: 'Vahvista toiminto',
    confirmationText: 'Jatka',
    cancellationText: 'Peruuta',
    allowClose: false
  };

  useEffect(() => {
    initDayjs(locale);
    setLocalization(getLocalization(locale));
    setTheme(createTheme(getLocalization(locale)));
  }, [locale])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ConfirmProvider defaultOptions={confirmOptions}>
        <SnackbarProvider maxSnack={3}>
          <QueryClientProvider client={queryClient}>
            <AnonMain />
          </QueryClientProvider>
        </SnackbarProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default AnonApp;
