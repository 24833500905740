import { Box } from "@mui/system";
import { Election, Vote } from "../../models/apiSchema";
import CandidateItemList from "./CandidateItemList";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";

interface ElectionCandidatesProps {
  election: Election;
  search?: string;
  onVote: (vote: Vote | null) => void;
}

const filterCandidates = (election: Election, search: string) => {
  return search === '' ? election.candidateLists : election.candidateLists.map(candidateList => {
    const filteredCandidates = candidateList.candidates.filter(candidate =>
      candidate.name.toLowerCase().includes(search.toLowerCase()) ||
      candidate.key.toLowerCase().includes(search.toLowerCase()
    ));
    return { ...candidateList, candidates: filteredCandidates };
  }).filter(candidateList => candidateList.candidates.length > 0);
}

const ElectionCandidates: React.FC<ElectionCandidatesProps> = ({ election, search = "", onVote }) => {
  const [candidateLists, setCandidateLists] = useState(election.candidateLists);

  useEffect(() => {
    setCandidateLists(filterCandidates(election, search));
  }, [election, search]);

  return (
    <>
      {candidateLists.map(candidateList =>
        <Box mt={3} key={candidateList.key}>
          <CandidateItemList candidateList={candidateList} onVote={onVote} />
        </Box>
      )}
      {candidateLists.length === 0 && <Box mt={3}>
        <Alert severity="info">Haulla ei löytynyt ehdokkaita</Alert>
      </Box>}
    </>
  );
}

export default ElectionCandidates;
