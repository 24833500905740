import { createSlice } from "@reduxjs/toolkit";

export interface ISettings {
  _producerNumber: number | null;
  backgroundImage: string | null;
  footerImage: string | null;
}

const initialState: ISettings = {
  _producerNumber: null,
  backgroundImage: null,
  footerImage: null
};

const slice = createSlice({
  name: "ui/settings",
  initialState: initialState,
  reducers: {
    producerNumberChanged: (state, action) => {
      state._producerNumber = action.payload.producerNumber ? Number(action.payload.producerNumber) : null;
    },
    changeBackgroundImage: (state, action) => {
      state.backgroundImage = action.payload.backgroundImage;
    },
    changeFooterImage: (state, action) => {
      state.footerImage = action.payload.footerImage;
    },
    resetImages: (state) => {
      state.backgroundImage = initialState.backgroundImage;
      state.footerImage = initialState.footerImage;
    }
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", () => {
      return { ...initialState };
    });
  }
});

export const {
  producerNumberChanged,
  changeBackgroundImage,
  changeFooterImage,
  resetImages
} = slice.actions;

export default slice.reducer;
