import React from "react";
import { Box, styled } from "@mui/system";
import { Route, Routes } from "react-router-dom";
import Header from "../Header/Header";
import Content from "../Content/Content";
import Footer from "../Footer/Footer";
import Home from "../../../views/Home/Home";
import ElectionView from "../../../views/Election/Election";
import Results from "../../../views/Results/Results";

const MainContainer = styled(Box)(({ theme }) => ({
  padding: '0 24px 24px 24px',
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column'
}));

const Main: React.FC = () => {
  return (
    <MainContainer>
      <Header />
      <Content>
        <Routes>
          <Route path="vaalit/:electionId" element={<ElectionView />} />
          <Route path="tulokset" element={<Results />} />
          <Route path="" element={<Home />} />
        </Routes>
      </Content>
      <Footer />
    </MainContainer>
  );
};

export default Main;
