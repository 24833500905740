import { Alert } from "@mui/material";
import { AxiosError } from "axios";
import { UseQueryResult } from "react-query";
import ErrorAlert from "../ErrorAlert/ErrorAlert";

interface QueryResultProps<T> {
  query: UseQueryResult<T,AxiosError>;
  result (data: T): React.ReactNode;
  loading?: string;
}

function QueryResult<T>({ query, result, loading = undefined }: QueryResultProps<T>) {

  if (query.isLoading)
    return <Alert severity="info">{loading || 'Ladataan tietoja...'}</Alert>;

  if (query.isError)
    return <ErrorAlert error={query.error as AxiosError} />

  if (query.isSuccess)
    return <>{result(query.data)}</>;

  return null;
};

export default QueryResult;
