import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { persistor, store } from './shared/store';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AnonApp from './AnonApp';

const root = createRoot(
  document.getElementById('root')!
);

root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route path="/aanesta/*" element={<AnonApp />} />
            <Route path="/*" element={<App />} />
          </Routes>          
        </PersistGate>
      </Provider>
    </Router>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
