import React from "react";
import { Box, styled } from "@mui/system";
import { Route, Routes } from "react-router-dom";
import Content from "../Content/Content";
import Footer from "../Footer/Footer";
import AnonNavigation from "../AnonHeader/AnonNavigation";
import AnonElectionView from "../../../views/Anon/AnonElection";

const MainContainer = styled(Box)(({ theme }) => ({
  padding: '0 24px 24px 24px',
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column'
}));

const AnonMain: React.FC = () => {
  return (
    <MainContainer>
      <AnonNavigation />
      <Content>
        <Routes>
          <Route path=":electionId/:producerNumber" element={<AnonElectionView />} />
        </Routes>
      </Content>
      <Footer />
    </MainContainer>
  );
};

export default AnonMain;
