import { Producer, ProducerList } from "../models/apiSchema";
import http from "./httpService";

const API_URL = process.env.REACT_APP_API_BASE_URL + '/producers';

export const getProducers = async () => {
  const resp = await http.get<ProducerList>(`${API_URL}`);
  return resp.data;
};

export const searchProducers = async (search: string) => {
  const resp = await http.get<ProducerList>(`${API_URL}?search=${search}`);
  return resp.data;
};

export const getProducersByCategory = async (category: string) => {
  const resp = await http.get<ProducerList>(`${API_URL}?category=${category}`);
  return resp.data;
};

export const getProducerById = async (producerId: string) => {
  const resp = await http.get<Producer>(`${API_URL}/${producerId}`);
  return resp.data;
};
