import { Election, ElectionStatus, Vote } from "../models/apiSchema";
import http from "./httpService";

const API_URL = process.env.REACT_APP_API_BASE_URL_LINKVOTE + '/electionslink';

export const getLinkElection = async (electionId: string, producerNumber: string, signature: string) => {
  const resp = await http.get<Election>(`${API_URL}/${electionId}/${producerNumber}`, { params: { s: signature} });
  return resp.data;
};

export const getLinkElectionStatus = async (electionId: string, producerNumber: string, signature: string) => {
  const resp = await http.get<ElectionStatus>(`${API_URL}/${electionId}/${producerNumber}/status`, { params: { s: signature} });
  return resp.data;
};

export const castLinkElectionVote = async (electionId: string, vote: Vote, producerNumber: string, signature: string) => {
  const resp = await http.post<void>(`${API_URL}/${electionId}/${producerNumber}/vote`, vote, { params: { s: signature} });
  return resp.data;
};
