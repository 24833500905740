import React, { useEffect } from "react";
import { Box, styled } from "@mui/system";
import { useQuery, useQueryClient } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import QueryResult from "../../shared/components/QueryResult/QueryResult";
import { Alert, IconButton, InputAdornment, TextField } from "@mui/material";
import { Election, ElectionStatus, Vote } from "../../shared/models/apiSchema";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import ElectionCandidates from "../../shared/components/Election/ElectionCandidates";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import VoteModal from "../../shared/components/Election/VoteModal";
import ElectionStatusInfo from "../Election/ElectionStatusInfo";
import { changeBackgroundImage, changeFooterImage } from "../../shared/store/ui/settings";
import { useAppDispatch } from "../../shared/store/hooks";
import { getLinkElection, getLinkElectionStatus } from "../../shared/services/electionLinkService";
import Markdown from "react-markdown";


const AnonElectionContainer = styled(Box)({
  minHeight: '100%'
});

const okToVote = (status: ElectionStatus) => status.hasAccess && status.hasStarted && !status.hasEnded && status.voted == null;

const AnonElectionView: React.FC = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  
  const [search, setSearch] = React.useState("");
  const [vote, setVote] = React.useState<Vote|null>(null);

  const electionStatus = useQuery<ElectionStatus,AxiosError>(["electionStatus", params.electionId],
    () => getLinkElectionStatus(params.electionId!, params.producerNumber!, searchParams.get("s")!),
    {
      enabled: !!params.electionId && !!params.producerNumber && !!searchParams.get("s"),
      cacheTime: 0
    }
  );

  const election = useQuery<Election,AxiosError>(["election", params.electionId],
    () => getLinkElection(params.electionId!, params.producerNumber!, searchParams.get("s")!),
    {
      enabled: !!params.electionId && electionStatus.isSuccess && okToVote(electionStatus.data),
      cacheTime: 0      
    }
  );

  useEffect(() => {
    if (election.data?.backgroundImage) {
      dispatch(changeBackgroundImage({ backgroundImage: election.data?.backgroundImage }));
    }
    if (election.data?.footerImage) {
      dispatch(changeFooterImage({ footerImage: election.data?.footerImage }));
    }  
  }, [dispatch, election.data]);

  const handleVote = (vote: Vote | null) => {
    setVote(vote);
  }

  const handleClose = (result: boolean) => {
    setVote(null);
    if (result) {
      queryClient.invalidateQueries();
      electionStatus.refetch();
    }
  }

  return (
    <AnonElectionContainer>
      <QueryResult query={electionStatus} loading="Tarkastetaan äänioikeutta..."
        result={(data) => <ElectionStatusInfo status={data} showBack={false} />} />
      <QueryResult query={election} loading="Noudetaan vaalitietoja..." result={(data) =>
        <>
          {okToVote(electionStatus.data!) &&<Box>
            <Box my={2}>
              <small>Äänestysaika: {dayjs(data.votingStarts).format('D.M.YYYY H:mm')} - {dayjs(data.votingEnds).format('D.M.YYYY H:mm')}</small>
            </Box>
            <Box my={2} sx={{ whiteSpace: "pre-wrap" }}>
              <Markdown>{data.description}</Markdown>
            </Box>
            <Alert severity="info">
              Voit valita yhden ehdokkaan haluamastasi listasta. Jatka äänen antamista painamalla ehdokkaan nimeä.
            </Alert>
            <Box mt={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                placeholder="Hae ehdokasta..."
                value={search}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setSearch(event.target.value); }}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                  endAdornment: <InputAdornment position="end">
                    <IconButton onClick={() => setSearch("")} edge="end"><ClearIcon/></IconButton>
                  </InputAdornment>
                }}
              />            
              <ElectionCandidates election={data} search={search} onVote={handleVote} />
            </Box>
          </Box>}
          <VoteModal election={data} vote={vote} onClose={handleClose} producerNumber={params.producerNumber} signature={searchParams.get("s")} />
        </>
      } />
    </AnonElectionContainer>
  );
};

export default AnonElectionView;