import * as React from 'react';
import { Container, Paper} from '@mui/material';
import { styled } from '@mui/system';
import { useAppSelector } from '../../store/hooks';
// import backgroundImage from '../../../assets/images/img-default-bg.jpg';

interface ContentBackgroundProps {
  imageUrl: string;
}

const ContentBackground = styled(Paper, {
  shouldForwardProp: (prop: string) => prop !== 'imageUrl',
})<ContentBackgroundProps>(({ theme, imageUrl }) => ({  
  flexGrow: 1,
  backgroundColor: 'gray',
  backgroundImage: `url(${imageUrl || '/lsovaalit_ylakuva_2024.jpg'})`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'center',
  backgroundPositionY: imageUrl ? 'bottom' : '-160px',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  padding: '24px',
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  borderRadius: 0
}));

const ContentContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: 'white',
  padding: '24px',
  [theme.breakpoints.down('md')]: {
    padding: '16px',
  },
}));

interface ContentProps {
  children: any;
};

const Content: React.FC<ContentProps> = ({ children }) => {
  const backgroundImage = useAppSelector((state) => state.ui.settings.backgroundImage);
  
  return (
    <ContentBackground imageUrl={backgroundImage}>
      <Container maxWidth="lg">
        <ContentContainer>
          {children}
        </ContentContainer>
      </Container>
    </ContentBackground>
  );
}
export default Content
