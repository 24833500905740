import { Alert } from "@mui/material";
import { AxiosError } from "axios";

interface ErrorAlertProps {
  error: AxiosError;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ error }) => {
  return <Alert severity="error">
    {error.response?.data.detail || error.response?.data.message || error.response?.data.title || 'Tuntematon virhe taustajärjestelmässä'}
  </Alert>;
};

export default ErrorAlert;
