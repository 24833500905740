import axios, { AxiosRequestConfig } from "axios";
import { store } from "../store";
import auth from "./authService"

// axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use(
  async (config) => {
    let _config = config || {} as AxiosRequestConfig
    if (!_config.headers)
      _config.headers = {};

    const state = store.getState();
    if (state?.auth.account) {
      const token = await auth.getAccessToken(state?.auth.account);
      if (token)
        _config.headers["authorization"] = `Bearer ${token}`;
    }

    if (process.env.REACT_APP_API_SUBSCRIPTION_KEY)
      _config.headers["Ocp-Apim-Subscription-Key"] = process.env.REACT_APP_API_SUBSCRIPTION_KEY;
    if (state?.auth.producer)
      _config.headers["x-producerportal-currentproducer"] = state?.auth.producer.id;

      return await Promise.resolve(config);
  },
  err => Promise.reject(err)
);

axios.interceptors.response.use(
  (resp) => resp,
  (err) => {
    console.error("Api error-response", err);
    if (err?.response?.status === 401) {
      const state = store.getState();
      if (state?.auth.account) {
        auth.logout();
      }
    }
    return Promise.reject(err);
  }
);

// const setRequestHeaders = (headers: any) => {
//   axios.defaults.headers.common = {
//     ...axios.defaults.headers.common,
//     ...headers,
//   };
// };

const http = {
    // setRequestHeaders,
    get: axios.get,
    put: axios.put,
    post: axios.post,
    delete: axios.delete,
    request: axios.request
};
export default http;