import { AppBar, Box, styled, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
//import { ReactComponent as SvgLogo } from "../../../assets/images/HKScan.svg";
import HKLogo from "../../../assets/images/hkfoods.png";
import PngLogo from "../../../assets/images/kivinetti.png";
import { useAppSelector } from "../../store/hooks";
import { toimintayksikko } from "../../store/auth";
import NavMenu, { IMenuItem } from "./NavMenu";
// import { useAppSelector } from "../../store/hooks";

const NavigationAppBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  fontFamily: "Avenir Next W1G Medium",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  textAlign: "right",
}));

const Logo = styled('img')({
  width: '128px',
  pointerEvents: 'none',
  marginTop: '8px'
});

const KivinettiLogo = styled('img')({
  width: '102px',
  pointerEvents: 'none',
  marginTop: '6px'
});

const Separator = styled('span')(({ theme }) => ({
  margin: "0 12px",
  color: '#777',
  fontWeight: 'normal'
}));

const SubTitle = styled('span')(({ theme }) => ({
  color: '#85293F',
  fontSize: '0.8em',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
}));

const menuItems : IMenuItem[] = [
  { title: "Kaikki vaalit", link: "/" },
  { title: "Tulokset", link: "/tulokset", roles: ['Vaalit.Results'] }
];

const Navigation: React.FC = () => {
  const producer = useAppSelector((state) => state.auth.producer);
  
  return (
    <NavigationAppBar position="static" color="default" elevation={0}>
      <Toolbar>
        <Box sx={{ textAlign: 'left' }}>
          <Link to={"/"}>{toimintayksikko(producer) === 'LSO' ? <Logo src={HKLogo} /> : <KivinettiLogo src={PngLogo} /> }</Link>          
        </Box>
        <Box sx={{ whitespace: 'nowrap', marginTop: '-2px' }}>
          <Typography variant="h3" color="primary" sx={{margin: 0}}>
            <Box>
              <Separator>|</Separator>
              <Link to={"/"} style={{ textDecoration: 'none' }}><SubTitle>Vaalit</SubTitle></Link>
            </Box>
          </Typography>
        </Box>
        <NavMenu menuItems={menuItems} />
      </Toolbar>
    </NavigationAppBar>
  );
}
export default Navigation
