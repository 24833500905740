import * as React from 'react';
import { Box, Grid, Hidden, Container } from '@mui/material';
import { styled } from '@mui/system';
// import { ReactComponent as LogoSvg } from "../../../assets/images/HKScan.svg";
import { ReactComponent as HKScanEnvironmentLeftSvg } from "../../../assets/images/hkscan_environment_left.svg";
import { ReactComponent as HKScanEnvironmentRightSvg } from "../../../assets/images/hkscan_environment_right.svg";
import { useAppSelector } from '../../store/hooks';

const FooterContainer = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  textAlign: "center",
  color: "#fff",
  fontSize: "0.9em",
  minHeight: "180px",
  backgroundColor: theme.palette.primary.main
}));

// const Logo = styled(LogoSvg)(({ theme }) => ({
//   height: "20px",
//   pointerEvents: "none",
//   "& > *": {
//     fill: "#fff !important",
//   }
// }));

// const LogoContainer = styled(Box)(({ theme }) => ({
//   paddingTop: "20%",
//   paddingBottom: "10%",
//   display: "block",
//   whiteSpace: "nowrap",
//   minWidth: "120px"
// }));

const HKScanEnvironmentLeft = styled(HKScanEnvironmentLeftSvg)(({ theme }) => ({
  padding: "60px 0",
  width: '180px',
  position: "relative",
  right: "-10%",
  "& > *": {
    fill: "#C9C8C6",
  }
}));

const HKScanEnvironmentRight = styled(HKScanEnvironmentRightSvg)(({ theme }) => ({
  padding: "60px 0",
  width: '180px',
  position: "relative",
  left: "-10%",
  "& > *": {
    fill: "#C9C8C6",
  }
}));

const FooterImage = styled('img')(({ theme }) => ({
  width: "auto",
  height: "180px",
  margin: "24px auto",
  [theme.breakpoints.down('sm')]: {
    width: "40%",
  },
}));

const Footer: React.FC = () => {
  const footerImage = useAppSelector((state) => state.ui.settings.footerImage);

  return (
    <FooterContainer>
      <Container maxWidth={"xl"}>
        <Grid container spacing={4} alignItems="flex-start">
          <Hidden mdDown>
            <Grid item xs={12} md={4}>
              <HKScanEnvironmentLeft />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={4}>
            {footerImage && <FooterImage src={footerImage} alt="" />}
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} md={4}>
              <HKScanEnvironmentRight />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </FooterContainer>
  );
}
export default Footer
