import React, { useEffect } from "react";
import { Box, styled } from "@mui/system";
import { Avatar, List, ListItemButton, ListItemAvatar, Alert, ListItemText, ListItem, Typography } from "@mui/material";
import { useQuery } from "react-query";
// import { useAppSelector } from "../../shared/store/hooks";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import QueryResult from "../../shared/components/QueryResult/QueryResult";
import { getElections } from "../../shared/services/electionService";
import { AxiosError } from "axios";
import { Election } from "../../shared/models/apiSchema";
import BallotIcon from '@mui/icons-material/Ballot';
import dayjs from "dayjs";
import { useAppDispatch } from "../../shared/store/hooks";
import { resetImages } from "../../shared/store/ui/settings";

const HomeContainer = styled(Box)({
  minHeight: '100%'
});

const ElectionAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main
}));

const Home: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const elections = useQuery<Election[],AxiosError>('elections', () => getElections());

  useEffect(() => {
    dispatch(resetImages());
  }, [dispatch]);

  return (
    <HomeContainer>
      <PageHeader level="1">Käynnissä olevat sekä tulevat vaalit</PageHeader>
      <Box mt={1}>
        <QueryResult query={elections} result={(data) => data.length > 0
          ? <List>{data.map((election) =>
              <ListItem key={election.id} dense disablePadding>
                <ListItemButton onClick={() => navigate(`/vaalit/${election.id}`)}>
                  <ListItemAvatar>
                    <ElectionAvatar>
                      <BallotIcon />
                    </ElectionAvatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<Typography variant="h5">{election.title}</Typography>}
                    secondary={<>
                      Äänestysaika: {dayjs(election.votingStarts).format('D.M.YYYY H:mm')}
                      {" - "}
                      {dayjs(election.votingEnds).format('D.M.YYYY H:mm')}
                    </>}
                  />
                </ListItemButton>
              </ListItem>
            )}</List>
          : <Alert severity="info">Sinulle ei tällä hetkellä löydy käynnissä olevia tai tulevia vaaleja</Alert>
        } />
      </Box>
    </HomeContainer>
  );
};

export default Home;
