import { AxiosError } from "axios";
import { ElectionResult } from "../models/apiSchema";
import http from "./httpService";
import { enqueueSnackbar } from 'notistack'

const API_URL = process.env.REACT_APP_API_BASE_URL + '/results';

export const getElectionResults = async (params?: any) => {
  const resp = await http.get<ElectionResult[]>(`${API_URL}`, { params });
  return resp.data;
};

export const downloadElectionResult = async (electionId: string, filename: string) => {
  http
    .get(`${API_URL}/${electionId}`, { responseType: 'blob' })
    .then((resp) => {
      const href = window.URL.createObjectURL(resp.data);
      const anchorElement = document.createElement('a');
  
      anchorElement.href = href;
      anchorElement.download = filename;
  
      document.body.appendChild(anchorElement);
      anchorElement.click();
  
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);  
    })
    .catch(async (error: AxiosError) => {
      const data = JSON.parse(await error.response?.data.text());
      console.error(data);
      enqueueSnackbar(data?.detail || 'Tulosten lataaminen epäonnistui', { variant: 'error' });  
    });
};
