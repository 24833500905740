import { Card, CardHeader, Divider, List } from "@mui/material";
import { CandidateList, Vote } from "../../models/apiSchema";
import CandidateItem from "./CandidateItem";
import { Fragment } from "react/jsx-runtime";

interface CandidateListProps {
  candidateList: CandidateList;
  onVote: (vote: Vote) => void;
}

const CandidateItemList: React.FC<CandidateListProps> = ({ candidateList, onVote }) => {
  return (
    <Card variant="elevation">
      <CardHeader sx={{ backgroundColor: "primary.main", color: 'primary.contrastText' }}
        title={candidateList.title}
        subheader={candidateList.description}
        subheaderTypographyProps={{ color: 'primary.contrastText' }}
      />
      <List disablePadding>
        {candidateList.candidates.map(candidate => <Fragment key={candidate.key}>
          <Divider component="li" />
          <CandidateItem candidate={candidate} onVote={(candidate) => onVote({ candidateKey: candidate.key, candidateListKey: candidateList.key })} />
        </Fragment>)}
      </List>
    </Card>
  );
}

export default CandidateItemList;
