import { AppBar, Box, styled, Toolbar, Typography } from "@mui/material";
import { ReactComponent as SvgLogo } from "../../../assets/images/HKScan.svg";

const NavigationAppBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  fontFamily: "Avenir Next W1G Medium",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  textAlign: "right",
}));

const Logo = styled(SvgLogo)({
  width: '102px',
  pointerEvents: 'none',
  marginTop: '8px'
});

const Separator = styled('span')(({ theme }) => ({
  margin: theme.spacing(2),
  color: '#777',
  fontWeight: 'normal'
}));

const SubTitle = styled('span')(({ theme }) => ({
  color: '#85293F',
  fontSize: '0.8em',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
}));

const AnonNavigation: React.FC = () => {
  
  return (
    <NavigationAppBar position="static" color="default" elevation={0}>
      <Toolbar>
        <Box sx={{ textAlign: 'left', minWidth: '80px', width: '104px' }}>
          <Logo/>
        </Box>
        <Box sx={{ whitespace: 'nowrap', marginTop: '-2px' }}>
          <Typography variant="h3" color="primary" sx={{margin: 0}}>
            <Box>
              <Separator>|</Separator>
              <SubTitle>Vaalit</SubTitle>
            </Box>
          </Typography>
        </Box>
      </Toolbar>
    </NavigationAppBar>
  );
}
export default AnonNavigation
