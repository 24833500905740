import { AuthenticationResult, Configuration, EventMessage, EventType, InteractionRequiredAuthError, PublicClientApplication, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import UserAccount from "../models/UserAccount";

const {
  REACT_APP_CLIENT_ID,
  REACT_APP_APPLICATION_ID,
  REACT_APP_SIGN_IN_SIGN_UP_POLICY
} = process.env;

const scopes = {
  producerPortal: `https://hkscanb2c.onmicrosoft.com/${REACT_APP_CLIENT_ID}/Co2.Write`
};

export const redirectRequest: RedirectRequest = {
  scopes: [
    scopes.producerPortal
  ],
  extraQueryParameters: {
    appId: `${REACT_APP_APPLICATION_ID}`
  },  
};

const b2cPolicies: any = {
  names: {
    signUpSignIn: REACT_APP_SIGN_IN_SIGN_UP_POLICY as string
  },
  authorities: {
    signUpSignIn: `https://hkscanb2c.b2clogin.com/hkscanb2c.onmicrosoft.com/${REACT_APP_SIGN_IN_SIGN_UP_POLICY}`
  },
  authorityDomain: "hkscanb2c.b2clogin.com",
};

export const msalConfig: Configuration = {
  auth: {
    clientId: `${REACT_APP_CLIENT_ID}`,
    authority: b2cPolicies.authorities.signUpSignIn,
    knownAuthorities: [
      b2cPolicies.authorityDomain
    ],
    navigateToLoginRequestUrl: true,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    tokenRenewalOffsetSeconds: 300,
    // loggerOptions: {
    //   loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
    //     if (containsPii) {
    //       return;
    //     }
    //     switch (level) {
    //       case LogLevel.Error:
    //         console.error(message);
    //         return;
    //       case LogLevel.Info:
    //         console.info(message);
    //         return;
    //       case LogLevel.Verbose:
    //         console.debug(message);
    //         return;
    //       case LogLevel.Warning:
    //         console.warn(message);
    //         return;
    //     }
    //   }
    // }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    if (event.payload) {
      console.debug("MSAL: setting active account on login");
      const result = event.payload as AuthenticationResult;
      const account = result.account;
      msalInstance.setActiveAccount(account);
    }
  }
});

const getMsalAccount = (homeAccountId?: string) => {
  if (homeAccountId) {
    const account = msalInstance.getAccountByHomeId(homeAccountId);
    return account;
  }

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) return accounts[0];
  return;
};

const loginRedirect = () => {
  msalInstance.loginRedirect(redirectRequest);
};

const getAccessToken = async (account: any): Promise<string> => {
  const msalAccount = getMsalAccount(account?.homeAccountId) || account;

  const tokenRequest: SilentRequest = {
    scopes: [
      scopes.producerPortal
    ],
    account: msalAccount as any,
  };

  try {
    const resp = await msalInstance.acquireTokenSilent(tokenRequest);
    return resp.accessToken;
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError)
      await msalInstance.acquireTokenRedirect(tokenRequest);
    throw err;
  }
};

const logout = () => {
  msalInstance.logoutRedirect();
};

const handleRedirectCallback = (onSuccess: any, onError: any) => {
  msalInstance.handleRedirectPromise().then(onSuccess).catch(onError);
};

const getAccount = (resp: AuthenticationResult): UserAccount => {
  return resp?.account ? new UserAccount(resp.account) : {} as UserAccount;
};

const auth = {
  loginRedirect,
  logout,
  getAccount,
  getMsalAccount,
  getAccessToken,
  handleRedirectCallback,
};
export default auth;
