import { Box } from "@mui/system";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { ElectionStatus } from "../../shared/models/apiSchema";
import { Alert, Button } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface ElectionStatusProps {
  status: ElectionStatus;
  showBack?: boolean
}

const okToVote = (status: ElectionStatus) => status.hasAccess && status.hasStarted && !status.hasEnded && status.voted == null;

const ElectionStatusInfo: React.FC<ElectionStatusProps> = ({ status, showBack = true }) => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader level="1">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <>{status.title}</>
        </Box>
      </PageHeader>
      {status.voted != null && <Box my={2}><Alert severity="success">
        Olet äänestänut tässä vaalissa {dayjs(status.voted).format('D.M.YYYY H:mm')}, kiitos!
      </Alert></Box>}
      {!status.hasAccess && <Box my={2}><Alert severity="error">
        Käyttäjälle tai tuottajanumerolle ei ole merkitty äänioikeutta tähän vaalin
      </Alert></Box>}
      {!status.hasStarted && <Box my={2}><Alert severity="info">
        Äänestysaika tässä vaalissa ei ole vielä alkanut
      </Alert></Box>}
      {status.hasEnded && <Box my={2}><Alert severity="warning">
        Äänestysaika tässä vaalissa on päättynyt
      </Alert></Box>}
      {showBack && !okToVote(status) && <Box mt={4}>
        <Button variant="contained" size="small" color="primary" onClick={() => navigate('/')}>Takaisin etusivulle</Button>
      </Box>}
    </>
  );
}

export default ElectionStatusInfo;
